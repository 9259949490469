import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import Footer from '../Footer';
import Header from '../Header'; // rendme to Header

import useTheme from '@/components/ThemeWrapper/useTheme';
import { APP_STATUS_IDLE, BRAND_APPROVAL_STATUSES } from '@/config/common';
import { ROLES } from '@/config/user';

import './signed-in-layout.scss';

export const SignInLayout = ({ userInfo, servicePartner, status, children, greyBg }) => {
  const { setTheme } = useTheme();

  useEffect(() => {
    document.body.classList.add('signed-in');

    const [mainBrand = {}] = servicePartner?.brands || [];
    setTheme(mainBrand?.brandId);

    return () => {
      document.body.classList.remove('signed-in');
    };
  }, [servicePartner]);

  const spRoles = userInfo?.spRoles || [];
  const userSubscription = userInfo?.recurringPayment;

  const isAdmin = spRoles?.some(role => role === ROLES.admin);
  const isBuyer = spRoles?.some(role => role === ROLES.buyer);
  const isBuyerOrAdmin = isAdmin || isBuyer;

  const userHasBrands = servicePartner?.brands?.length > 0;

  const allBrandsAreApproved =
    userHasBrands && servicePartner.brands.every(brand => brand.approvalStatus === BRAND_APPROVAL_STATUSES.APPROVED);

  const isPremiumButtonVisible = isBuyerOrAdmin && allBrandsAreApproved && !userSubscription?.isValid;

  return (
    <div>
      {/* TODO: Move to AppStatus component and fire notification on status change */}
      {status?.statusCode?.value !== APP_STATUS_IDLE && (
        <div role="status" className="status-banner">
          {status.statusText}
        </div>
      )}

      <Header />

      <section id="main-content" className={greyBg && 'grey-bg'}>
        <div className="container-fluid">{children}</div>
      </section>

      <Footer
        subscription={userSubscription?.subscription}
        isPremiumButtonVisible={isPremiumButtonVisible}
        isAdmin={isAdmin}
      />
    </div>
  );
};

SignInLayout.propTypes = {
  userInfo: PropTypes.object,
  servicePartner: PropTypes.object,
  children: PropTypes.node,
  status: PropTypes.object,
  greyBg: PropTypes.bool,
};

export default connect(({ app: { userInfo, servicePartner, status, systems } }) => ({
  userInfo,
  systems,
  servicePartner,
  status, // referrs to app status
}))(SignInLayout);
