export const findGroupById = (groups, findId, findCb = null) => {
  for (let gIndex = 0; gIndex < groups.length; gIndex++) {
    const group = groups[gIndex];
    if (group.id === findId) {
      if (findCb) {
        findCb(groups, gIndex);
      }
      return group;
    } else if (group.childGroups && group.childGroups.length) {
      const subGroup = findGroupById(group.childGroups, findId, findCb);
      if (subGroup) {
        return subGroup;
      }
    }
  }

  return null;
};

export const getPathToSelectedChildGroup = (groups, targetId, path = []) => {
  if (!Array.isArray(groups)) {
    return null;
  }

  for (const group of groups) {
    if (group.id === targetId) {
      return path;
    }

    const currentPath = [...path, group.id];

    if (Array.isArray(group.childGroups) && group.childGroups.length > 0) {
      const result = getPathToSelectedChildGroup(group.childGroups, targetId, currentPath);
      if (result) {
        return result;
      }
      return currentPath;
    }
  }
  return null;
};
