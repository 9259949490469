// TODO: Rename file to config.ts

export const Brands = {
  AIRSITE: 'airsite',
  AIT: 'ait',
  BIAWAR: 'biawar',
  CETETHERM: 'cetetherm',
  CLIMATEMASTER: 'climatemaster',
  CONTURA: 'contura',
  CTA: 'cta',
  CTC: 'ctc',
  DZD: 'dzd',
  DANDELION: 'dandelion',
  ENERTECH: 'enertech',
  HOIAX: 'hoiax',
  IEC: 'iec',
  JAMA: 'jama',
  JASPI: 'jaspi',
  METROTHERM: 'metrotherm',
  MHI: 'mhi',
  MYUPLINK: 'myuplink',
  NIBE: 'nibe',
  NIBEF: 'nibef',
  NOVELAN: 'novelan',
  ROTH: 'roth',
  TEMPEFF: 'tempeff',
  TIKI: 'tiki',
};

export const DEFAULT_BRAND = Brands.MYUPLINK;

const hideBrandForProdAndStageEnv = process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging';

export const brandsList = [
  {
    brand: Brands.AIT,
    url: '/assets/brands/ait/ait-logo.svg',
    companyUrl: 'https://www.alpha-innotec.com/',
    hide: false,
  },
  {
    brand: Brands.BIAWAR,
    url: '/assets/brands/biawar/biawar-logo.svg',
    companyUrl: 'https://www.biawar.com.pl/',
    hide: hideBrandForProdAndStageEnv,
  },
  {
    brand: Brands.CETETHERM,
    url: '/assets/brands/cetetherm/cetetherm-logo.svg',
    companyUrl: 'https://www.cetetherm.com/',
    hide: false,
  },
  {
    brand: Brands.CLIMATEMASTER,
    url: '/assets/brands/climatemaster/climatemaster-logo.svg',
    companyUrl: 'https://www.climatemaster.com/',
    hide: false,
  },
  {
    brand: Brands.CONTURA,
    url: '/assets/brands/contura/contura-logo.svg',
    companyUrl: 'https://www.contura.eu/',
    hide: false,
  },
  { brand: Brands.CTA, url: '/assets/brands/cta/cta-logo.svg', companyUrl: 'https://www.cta.ch', hide: false },
  { brand: Brands.CTC, url: '/assets/brands/ctc/ctc-logo.svg', companyUrl: 'https://ctc-heating.com/', hide: false },
  {
    brand: Brands.DZD,
    url: '/assets/brands/dzd/dzd-logo.svg',
    companyUrl: 'https://dzd.cz/',
    hide: hideBrandForProdAndStageEnv,
  },
  {
    brand: Brands.DANDELION,
    url: '/assets/brands/dandelion/dandelion-logo.svg',
    companyUrl: 'https://dandelionenergy.com/',
    hide: hideBrandForProdAndStageEnv,
  },
  {
    brand: Brands.ENERTECH,
    url: '/assets/brands/enertech/enertech-logo.svg',
    companyUrl: 'https://enertechusa.com/',
    hide: false,
  },
  { brand: Brands.HOIAX, url: '/assets/brands/hoiax/hoiax-logo.svg', companyUrl: 'https://www.hoiax.no/', hide: false },
  {
    brand: Brands.IEC,
    url: '/assets/brands/iec/iec-logo.svg',
    companyUrl: 'https://iec-okc.com/',
    hide: false,
  },
  {
    brand: Brands.JAMA,
    url: '/assets/brands/jama/jama-logo.svg',
    companyUrl: 'https://jaspi.fi/',
    hide: false,
  },
  {
    brand: Brands.JASPI,
    url: '/assets/brands/jaspi/jaspi-logo.svg',
    companyUrl: 'https://jaspi.fi/',
    hide: false,
  },
  {
    brand: Brands.METROTHERM,
    url: '/assets/brands/metrotherm/metrotherm-logo.svg',
    companyUrl: 'https://www.metrotherm.dk/',
    hide: hideBrandForProdAndStageEnv,
  },
  {
    brand: Brands.MHI,
    url: '/assets/brands/mhi/mhi-logo.svg',
    companyUrl: 'https://www.mhi.com',
    hide: hideBrandForProdAndStageEnv,
  },
  { brand: Brands.NIBE, url: '/assets/brands/nibe/nibe-logo.svg', companyUrl: 'https://www.nibe.eu/', hide: false },
  { brand: Brands.NIBEF, url: '/assets/brands/nibef/nibef-logo.svg', companyUrl: 'https://www.nibe.eu/', hide: true },
  {
    brand: Brands.AIRSITE,
    url: '/assets/brands/airsite/airsite-logo.svg',
    companyUrl: 'http://www.nibeairsite.com/sv/',
    hide: hideBrandForProdAndStageEnv,
  },
  {
    brand: Brands.NOVELAN,
    url: '/assets/brands/novelan/novelan-logo.svg',
    companyUrl: 'https://www.novelan.com/',
    hide: false,
  },
  {
    brand: Brands.ROTH,
    url: '/assets/brands/roth/roth-logo.svg',
    companyUrl: 'https://www.roth-industries.com/',
    hide: false,
  },
  {
    brand: Brands.TEMPEFF,
    url: '/assets/brands/tempeff/tempeff-logo.svg',
    companyUrl: 'https://www.tempeff.com/',
    hide: hideBrandForProdAndStageEnv,
  },
  {
    brand: Brands.TIKI,
    url: '/assets/brands/tiki/tiki-logo.svg',
    companyUrl: 'https://www.tikigroup.eu/',
    hide: hideBrandForProdAndStageEnv,
  },
];
