import React, { lazy } from 'react';

import { connect } from 'react-redux';
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom';

import PropTypes from 'prop-types';

import SignedInLayout from '@/components/layout/SignInLayout';

import { allRoutes, AnonymousGuard, DefaultLayout } from './config';
import { SharedRouteProps } from './types';

const NotFound = lazy(() => import(/* webpackChunkName: "NotFound" */ '@/pages/NotFound'));

type ProRouteProps = RouteProps &
  SharedRouteProps & {
    isHidden: boolean;
  } & any; // TBD in next related task which is not Hotfix

export function Routes({ userInfo }) {
  const routeGenerator =
    ({ layout: Layout = DefaultLayout, page: Page, guard: Guard = AnonymousGuard, ...routeProps }) =>
    // eslint-disable-next-line react/display-name
    props => {
      return (
        <Guard userInfo={userInfo} {...routeProps}>
          <Layout {...props}>
            <Page {...props} />
          </Layout>
        </Guard>
      );
    };

  const FallbackLayout = userInfo === null ? DefaultLayout : SignedInLayout;

  return (
    <Switch>
      {allRoutes.map(
        ({ path, exact = true, isHidden = false, ...routeProps }: ProRouteProps, key) =>
          // isHidden flag hides the route according to conditions
          !isHidden && <Route exact={exact} key={key} path={path} render={routeGenerator(routeProps)} />,
      )}

      <Redirect exact key="home" from="/" to={userInfo ? '/systems' : '/login'} />

      <FallbackLayout>
        <Route path="*" key="404" component={NotFound} />
      </FallbackLayout>
    </Switch>
  );
}

Routes.propTypes = {
  userInfo: PropTypes.object,
};

export default connect(({ app: { userInfo } }) => ({
  userInfo,
}))(Routes);
